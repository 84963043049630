// ./app/providers.tsx

'use client';

import React from 'react';
import {
  useQuery,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';

// import { GlobalStateProvider } from '#/providers/GlobalStateContext';
import UserContextProvider from '#/providers/UserContext';
import WagmiProvider from '#/providers/W3Provider';

// Create a client
const queryClient = new QueryClient();

const RootProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <UserContextProvider>
          <WagmiProvider>{children}</WagmiProvider>
        </UserContextProvider>
      </QueryClientProvider>
    </div>
  );
};

// Exports
// ========================================================
export default RootProvider;
