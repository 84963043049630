'use client';
import { AvatarComponent } from '@rainbow-me/rainbowkit';

export const CustomAvatar: AvatarComponent = ({ address, ensImage, size }) => {
  return (
    <div
      style={{
        backgroundColor: '#000000',
        borderRadius: 999,
        height: size,
        width: size,
      }}
    >
      <img
        src={'/simpfinogrid.png'}
        width={100}
        height={100}
        style={{ borderRadius: 999 }}
      />
    </div>
  );
};
export default CustomAvatar;
