//./providers/wagmi/index.tsx
'use client';

import React from 'react';
import {
  RainbowKitAuthenticationProvider,
  RainbowKitProvider,
  darkTheme,
  DisclaimerComponent,
} from '@rainbow-me/rainbowkit';
import { WagmiConfig } from 'wagmi';

import CustomAvatar from './customAvatar';
import useAuth from './useAuth';

import WagmiConfigs from './wagmiConfigs';

const client = WagmiConfigs.client;
const chains = WagmiConfigs.chains;

const Disclaimer: DisclaimerComponent = ({ Text, Link }) => (
  <Text>
    By connecting your wallet, you agree to the{' '}
    <Link href="https://SimpFi.ai/terms">Terms of Gangster</Link> and
    acknowledge you have read and understand the protocol{' '}
    <Link href="https://SimpFi.ai/legal">Disclaimer</Link>
  </Text>
);

const WagmiProvider = ({ children }: { children: React.ReactNode }) => {
  const { authStatus, authAdapter } = useAuth();

  return (
    <WagmiConfig client={client}>
      <RainbowKitAuthenticationProvider
        adapter={authAdapter}
        status={authStatus}
      >
        <RainbowKitProvider
          coolMode
          chains={chains}
          modalSize="compact"
          theme={darkTheme({
            accentColor: '#26B400',
            accentColorForeground: 'white',
            borderRadius: 'small',
            fontStack: 'system',
            overlayBlur: 'large',
          })}
          appInfo={{
            appName: 'SimpFi ALPHA',
            disclaimer: Disclaimer,
            learnMoreUrl: 'https://SimpFi.Ai',
          }}
          avatar={CustomAvatar}
        >
          {children}
        </RainbowKitProvider>
      </RainbowKitAuthenticationProvider>
    </WagmiConfig>
  );
};
export default WagmiProvider;
